import { useFiserv, useFiservStacked1, useFiservStacked2, useFiservStacked3, useFiservStacked4 } from "../../utils/custom-hooks/image-hooks";

export default {
  data: {
    page: {
      theme: {
        logo: "light",
        type: "default",
        header: "light"
      },
      title: "Fiserv",
      uri: "/work/fiserv-dashboard/",
      // dateGmt: "2020-12-23T09:07:14",
      slug: "fiserv-dashboard",
      // modifiedGmt: "2021-05-18T21:09:15",
      seo: {
        canonical: "",
        metaDesc: "",
        opengraphDescription: "",
        opengraphSiteName: "Vivid",
        opengraphTitle: "Fiserv - Vivid",
        opengraphType: "article",
        title: "Fiserv - Vivid",
        twitterDescription: "",
        twitterTitle: "",
        twitterImage: null,
      },
      flexchildren: [
        {
          __typename: "HeroCta",
          backgroundGradientLight: "#fd6721",
          backgroundGradientDark: "#ffbe2d",
          backgroundImagePosition: "center",
          backgroundSize: "contain",
          cta: null,
          ctaVariant: "light",
          description: "How we’re helping to deliver new revenue streams.",
          descriptionColor: "#ffffff",
          title: "Realizing the untapped value of data",
          titleColor: "#ffffff",
          rightImage: {
            altText: "",
            useImage: useFiserv,
            localFile: {
              publicURL: "/static/626ef39d305a54a59bf684b0fa436368/Hero-Fiserv-1.png",
              extension: "png",
              childImageSharp: {
                fluid: {
                  base64:
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEZElEQVQ4y2WTe0zbVRTHT+lU6NiAAg2DDTC8lUKB0jFaQAaUyYDS0nUdpQxox+TZQkFeqx2w4YLZdPiKMHko+wOz/TNJNNv8gz/mH0gwGIiJEIKUyRsMjwRse4+3DdNMT/K9v19ycz/3e865B+A/wefHQnKCAPA+AN4FKON7Pv7sLAvrhJwJUYBrKzBDw/ACQKCXK0DIZWhTJjMrtcVO6uJSwN/H4X/BfSMcoqK4DPAXAAQIOOK4kNWbmSfxkyxffC/BeUUe6fGlq09gGsAzZqv6HERkawDbAxjvqPKZRSUaxkuwGF40JKVnAj8+jimIjwV+XExDqjgDBWfFB2+niSyNmaF4J8Mbu5JYaDjjOaVXS02x8soIvBcECmkOhJy7/LK7OF4UCJOSwd2NDT4enq+dToj/Let8JubmZlkvqRREyvO3SeIjLXcatbaPCxPwkakQH7QW/HW7Uv64QKkoDs++4vkPLDExEQTpOeDtdozp4gTAPu6qCn3dD99KTbFK8nJJVXkJ0UR7kY/KssnkD8PkQVed9QOt0nLPWI3fvl+Oj1oU2KVT/wgzMzMwNzcH+/v7DvDXN2sddQg/5f0s0IuFNQJPa44gDPmxXJLi54o3lJk4+/BzMtFVSgYrLpLWxmZS19K2b2zQo7GyyAhbW1uwubkJZrOZ0d/7xatXNcV2XvIZfw/0cz9q4544RjxdgKREh5B3swSYHBFEHhpUONtrwKcj94mhvtF2raUFr1ZU/RmTITsBS0tLjPX1debi4iIgol2sqsqK74I5bujHdrecDI5BOMLC7PgIHChOI77HnUlSLBd/Hr5FOjuMWFFRbamp0eElpbJHqlABLC8vAwXC/Pw8mzptXlhYWBoY/AprDfU2uVyOfkFcAq+4kyMApKk0F/s+bcbxwWvY3fMhKSgsIlrNFVSpClEsFvNTU1MdwICVlRXT6uqqeW1tDdvaOtDfP9AqTEzCkhINaWluQo1GQ+LeDCeuilpyt+8Wwb5cYixXIXVkLaSwrKzz38tkCpDJLjKAgvQbGxtIXTq0tLR8MDo6atPp9CQqikd4vDgik13A66brWFmjJ8r8XFIjDiPiFCGR5iusEkkeCoVCKZ8fDyKRiOlImTYmgEK76HeNdhsPwzo5+Yuto+MGEYlSSGRkNElPF6NObyDVhiaU5EktPF4MCgSnf+3uvs0sKSkCR8rT09MMQgjs7e3B9va2987OTgPVzMHBwQuwzWxetPb09JKMjExCy4FR3GjbkydPbWNjY1hWVlZYV1cLJpOJ6evr+++U0IMM+1u0d1qr1XpPTEzU0nJMvwBbLBby/Pkftv7+Advw8DeEXmovkbG+vh5oyk72cxwOx8FiUrlRnXJ2do5gs9l8+1hThVDFDg0NGWZnZ39aW1tHmgHaL9jd3cWRkZEOun+UykUikTA6OzvBycnJAbSvrEOofRY5FOzn4+MTTP/DqILs4Pb2dt3U1NQ4dW2H1R2+WQdBr9eDWq12wP4G3u02WDKW3TgAAAAASUVORK5CYII=",
                  aspectRatio: 1.25,
                  src: "/static/626ef39d305a54a59bf684b0fa436368/5d0e0/Hero-Fiserv-1.png",
                  srcSet:
                    "/static/626ef39d305a54a59bf684b0fa436368/5224a/Hero-Fiserv-1.png 200w,\n/static/626ef39d305a54a59bf684b0fa436368/d786d/Hero-Fiserv-1.png 400w,\n/static/626ef39d305a54a59bf684b0fa436368/5d0e0/Hero-Fiserv-1.png 581w",
                  srcWebp: "/static/626ef39d305a54a59bf684b0fa436368/db70d/Hero-Fiserv-1.webp",
                  srcSetWebp:
                    "/static/626ef39d305a54a59bf684b0fa436368/9e195/Hero-Fiserv-1.webp 200w,\n/static/626ef39d305a54a59bf684b0fa436368/40a1d/Hero-Fiserv-1.webp 400w,\n/static/626ef39d305a54a59bf684b0fa436368/db70d/Hero-Fiserv-1.webp 581w",
                  sizes: "(max-width: 581px) 100vw, 581px",
                  presentationWidth: 581,
                },
              },
            },
          },
          backgroundImage: null,
          overlapSection: null,
        },
        {
          __typename: "Overview",
          client: "Fiserv",
          description:
            "As a global leader in transaction processing, Fiserv has built massive reservoirs of financial information assets. But those reservoirs were largely untapped, prohibiting them from maximizing the value of their data.<br />\r\n<br />\r\nFor 8 years, we’ve worked with Fiserv and their subsidiary First Data to aggregate their disparate data repositories and productize their service and analytics offerings. Through a combination of customer-centric design, engineering support, and targeted go-to-market strategy, we’re helping reduce integration friction for their clients, expand into new lines of business, and revitalize existing revenue streams.<br />\r\n",
          industry: "Fintech",
          solutions:
            "Strategy, Architecture, Development, Branding, Design, Copywriting, Project Management",
          title: "Overview",
        },
        {
          __typename: "StackedImages",
          images: [
            {
              image: {
                altText: "",
                useImage: useFiservStacked1,
                localFile: {
                  publicURL:
                    "/static/0a213c925496372812c3b520a170be47/dfuzr_success_story_fiserv_ui_design_thumbnail_2.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAgME/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAYry7CjERCH/xAAcEAACAgIDAAAAAAAAAAAAAAABAgADBBESEyL/2gAIAQEAAQUCsLVyvIbl3Qu2j5DuQ3//xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQMBAT8BjL//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAHBAAAgEFAQAAAAAAAAAAAAAAAAESAhARITJB/9oACAEBAAY/AqomG5nLEa9t/8QAGhAAAwEBAQEAAAAAAAAAAAAAAREhADFBof/aAAgBAQABPyEwTkx4DoLIsk+mGO68kYHvHoB5v//aAAwDAQACAAMAAAAQG/8A/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhEf/aAAgBAwEBPxCVTB//xAAWEQADAAAAAAAAAAAAAAAAAAAQIUH/2gAIAQIBAT8QaD//xAAcEAEBAAIDAQEAAAAAAAAAAAABEQAhMUFxYdH/2gAIAQEAAT8QI4LZegrr8w8cTQIPR5jFcpdI4JeI1yA1dIa8Pmasuhc//9k=",
                      aspectRatio: 1.7391304347826086,
                      src: "/static/0a213c925496372812c3b520a170be47/a1eb1/dfuzr_success_story_fiserv_ui_design_thumbnail_2.jpg",
                      srcSet:
                        "/static/0a213c925496372812c3b520a170be47/4fa6c/dfuzr_success_story_fiserv_ui_design_thumbnail_2.jpg 200w,\n/static/0a213c925496372812c3b520a170be47/64b17/dfuzr_success_story_fiserv_ui_design_thumbnail_2.jpg 400w,\n/static/0a213c925496372812c3b520a170be47/a1eb1/dfuzr_success_story_fiserv_ui_design_thumbnail_2.jpg 800w,\n/static/0a213c925496372812c3b520a170be47/4336c/dfuzr_success_story_fiserv_ui_design_thumbnail_2.jpg 1130w",
                      srcWebp:
                        "/static/0a213c925496372812c3b520a170be47/b0751/dfuzr_success_story_fiserv_ui_design_thumbnail_2.webp",
                      srcSetWebp:
                        "/static/0a213c925496372812c3b520a170be47/9e195/dfuzr_success_story_fiserv_ui_design_thumbnail_2.webp 200w,\n/static/0a213c925496372812c3b520a170be47/40a1d/dfuzr_success_story_fiserv_ui_design_thumbnail_2.webp 400w,\n/static/0a213c925496372812c3b520a170be47/b0751/dfuzr_success_story_fiserv_ui_design_thumbnail_2.webp 800w,\n/static/0a213c925496372812c3b520a170be47/a8db0/dfuzr_success_story_fiserv_ui_design_thumbnail_2.webp 1130w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
            {
              image: {
                altText: "",
                useImage: useFiservStacked2,
                localFile: {
                  publicURL:
                    "/static/9c86e7881636e94b765847d6dd3d2539/dfuzr_success_story_fiserv_ui_design_thumbnail_3.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABanWF2cHUZ//EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMTETL/2gAIAQEAAQUCsA4nrEPHUaOMrS5E/8QAFhEBAQEAAAAAAAAAAAAAAAAAABES/9oACAEDAQE/Aa0//8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQIBAT8BQ//EABoQAAMAAwEAAAAAAAAAAAAAAAABERIhMQL/2gAIAQEABj8C9KWcNKu8LkkQWBrR/8QAGxABAQEAAwEBAAAAAAAAAAAAAREAQVFhITH/2gAIAQEAAT8huv2V+4MtaI4ym+JP3dSEMJ0gy+5iSOg3/9oADAMBAAIAAwAAABAPP//EABcRAQEBAQAAAAAAAAAAAAAAAAEAIUH/2gAIAQMBAT8QOJA7f//EABYRAQEBAAAAAAAAAAAAAAAAAAEAIf/aAAgBAgEBPxBIDt//xAAcEAEAAgMBAQEAAAAAAAAAAAABABEhMVFBgZH/2gAIAQEAAT8QQMo1fwsThYC16S+G2Dh9MRABoR5dmOTGkaW9qz7OnNSDc//Z",
                      aspectRatio: 1.7391304347826086,
                      src: "/static/9c86e7881636e94b765847d6dd3d2539/a1eb1/dfuzr_success_story_fiserv_ui_design_thumbnail_3.jpg",
                      srcSet:
                        "/static/9c86e7881636e94b765847d6dd3d2539/4fa6c/dfuzr_success_story_fiserv_ui_design_thumbnail_3.jpg 200w,\n/static/9c86e7881636e94b765847d6dd3d2539/64b17/dfuzr_success_story_fiserv_ui_design_thumbnail_3.jpg 400w,\n/static/9c86e7881636e94b765847d6dd3d2539/a1eb1/dfuzr_success_story_fiserv_ui_design_thumbnail_3.jpg 800w,\n/static/9c86e7881636e94b765847d6dd3d2539/4336c/dfuzr_success_story_fiserv_ui_design_thumbnail_3.jpg 1130w",
                      srcWebp:
                        "/static/9c86e7881636e94b765847d6dd3d2539/b0751/dfuzr_success_story_fiserv_ui_design_thumbnail_3.webp",
                      srcSetWebp:
                        "/static/9c86e7881636e94b765847d6dd3d2539/9e195/dfuzr_success_story_fiserv_ui_design_thumbnail_3.webp 200w,\n/static/9c86e7881636e94b765847d6dd3d2539/40a1d/dfuzr_success_story_fiserv_ui_design_thumbnail_3.webp 400w,\n/static/9c86e7881636e94b765847d6dd3d2539/b0751/dfuzr_success_story_fiserv_ui_design_thumbnail_3.webp 800w,\n/static/9c86e7881636e94b765847d6dd3d2539/a8db0/dfuzr_success_story_fiserv_ui_design_thumbnail_3.webp 1130w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
          ],
        },
        {
          __typename: "Quote",
          authorAndTitleCompany: null,
          quoteText:
            "Vivid's blend of professionalism, expertise, and experience is hard to find in an agency partner. They're able to make complex systems simple and look great. I’m glad I found them.",
        },
        {
          __typename: "StackedImages",
          images: [
            {
              image: {
                altText: "",
                useImage: useFiservStacked3,
                localFile: {
                  publicURL:
                    "/static/c17992dc24995248644e86a18eb1d13a/dfuzr_success_story_fiserv_ui_design_thumbnail_4.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAenisi4P/8QAGRABAAMBAQAAAAAAAAAAAAAAAQACIQMR/9oACAEBAAEFAn2CWgY6U50oz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EAB0QAAICAQUAAAAAAAAAAAAAAAERAAIxEBIhInH/2gAIAQEABj8CPKCnW+7xTMRxHUI6f//EABoQAAMAAwEAAAAAAAAAAAAAAAABESExUZH/2gAIAQEAAT8hTK5qo0l3GDXs8FvmZRo0tkpX0//aAAwDAQACAAMAAAAQsA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEAAgMBAAAAAAAAAAAAAAABABEhMVFh/9oACAEBAAE/EAKQxqV7dmiEWU24A7qHFu9MG+5IbOR1esBXHMzup//Z",
                      aspectRatio: 1.7391304347826086,
                      src: "/static/c17992dc24995248644e86a18eb1d13a/a1eb1/dfuzr_success_story_fiserv_ui_design_thumbnail_4.jpg",
                      srcSet:
                        "/static/c17992dc24995248644e86a18eb1d13a/4fa6c/dfuzr_success_story_fiserv_ui_design_thumbnail_4.jpg 200w,\n/static/c17992dc24995248644e86a18eb1d13a/64b17/dfuzr_success_story_fiserv_ui_design_thumbnail_4.jpg 400w,\n/static/c17992dc24995248644e86a18eb1d13a/a1eb1/dfuzr_success_story_fiserv_ui_design_thumbnail_4.jpg 800w,\n/static/c17992dc24995248644e86a18eb1d13a/4336c/dfuzr_success_story_fiserv_ui_design_thumbnail_4.jpg 1130w",
                      srcWebp:
                        "/static/c17992dc24995248644e86a18eb1d13a/b0751/dfuzr_success_story_fiserv_ui_design_thumbnail_4.webp",
                      srcSetWebp:
                        "/static/c17992dc24995248644e86a18eb1d13a/9e195/dfuzr_success_story_fiserv_ui_design_thumbnail_4.webp 200w,\n/static/c17992dc24995248644e86a18eb1d13a/40a1d/dfuzr_success_story_fiserv_ui_design_thumbnail_4.webp 400w,\n/static/c17992dc24995248644e86a18eb1d13a/b0751/dfuzr_success_story_fiserv_ui_design_thumbnail_4.webp 800w,\n/static/c17992dc24995248644e86a18eb1d13a/a8db0/dfuzr_success_story_fiserv_ui_design_thumbnail_4.webp 1130w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
            {
              image: {
                altText: "",
                useImage: useFiservStacked4,
                localFile: {
                  publicURL:
                    "/static/c94f14d6d72280c88b526fa36a28b501/dfuzr_success_story_fiserv_ui_design_thumbnail_5.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMGBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHUWucKEnyP/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAESISMv/aAAgBAQABBQI5Wk5uMMn1c//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAQACAwAAAAAAAAAAAAAAAAECEAARgf/aAAgBAQAGPwJXFJPJUu2//8QAGxAAAwEBAAMAAAAAAAAAAAAAAAERITFBUXH/2gAIAQEAAT8hTEKLtLsZJXU+6WmM94ItJTfA2vT/2gAMAwEAAgADAAAAEMD/AP/EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QjVP/xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QTJ//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhQVFxYf/aAAgBAQABPxDA+z0fYtisKjD2DQo4NG7jEIdBZZyOS5AzgqEVI8Z//9k=",
                      aspectRatio: 1.7391304347826086,
                      src: "/static/c94f14d6d72280c88b526fa36a28b501/a1eb1/dfuzr_success_story_fiserv_ui_design_thumbnail_5.jpg",
                      srcSet:
                        "/static/c94f14d6d72280c88b526fa36a28b501/4fa6c/dfuzr_success_story_fiserv_ui_design_thumbnail_5.jpg 200w,\n/static/c94f14d6d72280c88b526fa36a28b501/64b17/dfuzr_success_story_fiserv_ui_design_thumbnail_5.jpg 400w,\n/static/c94f14d6d72280c88b526fa36a28b501/a1eb1/dfuzr_success_story_fiserv_ui_design_thumbnail_5.jpg 800w,\n/static/c94f14d6d72280c88b526fa36a28b501/4336c/dfuzr_success_story_fiserv_ui_design_thumbnail_5.jpg 1130w",
                      srcWebp:
                        "/static/c94f14d6d72280c88b526fa36a28b501/b0751/dfuzr_success_story_fiserv_ui_design_thumbnail_5.webp",
                      srcSetWebp:
                        "/static/c94f14d6d72280c88b526fa36a28b501/9e195/dfuzr_success_story_fiserv_ui_design_thumbnail_5.webp 200w,\n/static/c94f14d6d72280c88b526fa36a28b501/40a1d/dfuzr_success_story_fiserv_ui_design_thumbnail_5.webp 400w,\n/static/c94f14d6d72280c88b526fa36a28b501/b0751/dfuzr_success_story_fiserv_ui_design_thumbnail_5.webp 800w,\n/static/c94f14d6d72280c88b526fa36a28b501/a8db0/dfuzr_success_story_fiserv_ui_design_thumbnail_5.webp 1130w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
          ],
        },
      ],
    },
  },
};
